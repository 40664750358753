import axios from '@/common/axios'
import qs from 'qs'

export function addPurifiedWaterUserTending (data) {
  return axios({
    method: 'post',
    url: '/device/purifiedWaterUserTending/add',
    data: qs.stringify(data)
  })
}

export function deletePurifiedWaterUserTending (id) {
  return axios({
    method: 'delete',
    url: '/device/purifiedWaterUserTending/delete/' + id
  })
}

export function updatePurifiedWaterUserTending (data) {
  return axios({
    method: 'put',
    url: '/device/purifiedWaterUserTending/update',
    data: qs.stringify(data)
  })
}

export function selectPurifiedWaterUserTendingInfo (id) {
  return axios({
    method: 'get',
    url: '/device/purifiedWaterUserTending/info/' + id
  })
}

export function selectPurifiedWaterUserTendingList (query) {
  return axios({
    method: 'get',
    url: '/device/purifiedWaterUserTending/list',
    params: query
  })
}
