<template>
  <div id="purifiedWaterUserTending">
    <el-dialog
      :title="purifiedWaterUserTendingFormTitle"
      width="1500px"
      :visible.sync="purifiedWaterUserTendingDialogVisible"
      :close-on-click-modal="false"
      @close="purifiedWaterUserTendingDialogClose"
    >
      <el-form
        ref="purifiedWaterUserTendingFormRef"
        :model="purifiedWaterUserTendingForm"
        :rules="purifiedWaterUserTendingFormRules"
        label-position="right"
        label-width="100px"
      >
        <el-row>
          <el-col :span="8">
            <el-form-item label="设备编号" prop="deviceId">
              <el-select
                v-model="purifiedWaterUserTendingForm.deviceId"
                placeholder="请选择设备"
                clearable
                @change="devChange"
              >
                <el-option
                  v-for="item in devList"
                  :key="item.id"
                  :label="item.deviceNo"
                  :value="item.id"
                >
                  <span style="float: left">{{ item.deviceNo }}</span>
                  <span style="float: right; color: #8492a6; font-size: 13px">{{ item.deviceName }}</span>
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="设备名称" prop="deviceName">
              <el-input v-model="purifiedWaterUserTendingForm.deviceName" placeholder="请输入设备名称" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="日期" prop="useDate">
              <el-date-picker
                v-model="purifiedWaterUserTendingForm.useDate"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="请输入日期"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="使用时间段" prop="useTimePeriod">
              <el-input v-model="purifiedWaterUserTendingForm.useTimePeriod" placeholder="请输入使用时间段" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="清洗情况" prop="cleaningSituation">
              <el-radio-group v-model="purifiedWaterUserTendingForm.cleaningSituation">
                <el-radio :label="1">
                  已清洗
                </el-radio>
                <el-radio :label="2">
                  未清洗
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="使用前" prop="beforeUse">
              <el-radio-group v-model="purifiedWaterUserTendingForm.beforeUse">
                <el-radio :label="1">
                  正常
                </el-radio>
                <el-radio :label="2">
                  异常
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="使用后" prop="afterUse">
              <el-radio-group v-model="purifiedWaterUserTendingForm.afterUse">
                <el-radio :label="1">
                  正常
                </el-radio>
                <el-radio :label="2">
                  异常
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="维护保养" prop="tending">
              <el-radio-group v-model="purifiedWaterUserTendingForm.tending">
                <el-radio :label="1">
                  是
                </el-radio>
                <el-radio :label="2">
                  否
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="使用人" prop="user">
              <el-input v-model="purifiedWaterUserTendingForm.user" placeholder="请输入使用人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="备注" prop="remarks">
              <el-input
                v-model="purifiedWaterUserTendingForm.remarks"
                :rows="3"
                type="textarea"
                placeholder="请输入备注"
                clearable
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="purifiedWaterUserTendingDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="purifiedWaterUserTendingFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="月份">
        <el-input v-model="searchForm.month" placeholder="请输入月份" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="purifiedWaterUserTendingPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeight()"
    >
      <el-table-column prop="deviceNo" label="设备编号" width="100" />
      <el-table-column prop="useDate" label="日期" width="110" />
      <el-table-column prop="useTimePeriod" label="使用时间段" />
      <el-table-column prop="cleaningSituation" label="清洗情况">
        <template slot-scope="scope">
          <span v-if="scope.row.cleaningSituation === 1">已清洗</span>
          <span v-if="scope.row.cleaningSituation === 2">未清洗</span>
        </template>
      </el-table-column>
      <el-table-column prop="beforeUse" label="使用前">
        <template slot-scope="scope">
          <span v-if="scope.row.beforeUse === 1">正常</span>
          <span v-if="scope.row.beforeUse === 2">异常</span>
        </template>
      </el-table-column>
      <el-table-column prop="afterUse" label="使用后">
        <template slot-scope="scope">
          <span v-if="scope.row.afterUse === 1">正常</span>
          <span v-if="scope.row.afterUse === 2">正常</span>
        </template>
      </el-table-column>
      <el-table-column prop="tending" label="维护保养">
        <template slot-scope="scope">
          <span v-if="scope.row.tending === 1">是</span>
          <span v-if="scope.row.tending === 2">否</span>
        </template>
      </el-table-column>
      <el-table-column prop="user" label="使用人" />
      <el-table-column prop="remarks" label="备注" />
      <el-table-column
        label="操作"
        align="center"
        width="200"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="purifiedWaterUserTendingPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import {
  addPurifiedWaterUserTending,
  deletePurifiedWaterUserTending,
  updatePurifiedWaterUserTending,
  selectPurifiedWaterUserTendingInfo,
  selectPurifiedWaterUserTendingList
} from '@/api/device/purifiedWaterUserTending'
import { selectBaseInfoList } from '@/api/device/baseInfo'

export default {
  data () {
    return {
      purifiedWaterUserTendingDialogVisible: false,
      purifiedWaterUserTendingFormTitle: '',
      purifiedWaterUserTendingForm: {
        id: '',
        deviceId: '',
        deviceNo: '',
        deviceName: '',
        useDate: '',
        useTimePeriod: '',
        cleaningSituation: '',
        beforeUse: '',
        afterUse: '',
        tending: '',
        user: '',
        remarks: ''
      },
      purifiedWaterUserTendingFormRules: {
        month: [{ required: true, message: '月份不能为空', trigger: ['blur', 'change']}]
      },
      purifiedWaterUserTendingPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        month: ''
      },
      devList: [],
      detailList: []
    }
  },
  created () {
    selectPurifiedWaterUserTendingList(this.searchForm).then(res => {
      this.purifiedWaterUserTendingPage = res
    })
    selectBaseInfoList().then(res => {
      this.devList = res.list
    })
  },
  methods: {
    purifiedWaterUserTendingDialogClose () {
      this.$refs.purifiedWaterUserTendingFormRef.resetFields()
      this.detailList = []
    },
    purifiedWaterUserTendingFormSubmit () {
      console.log('dd:' + this.purifiedWaterUserTendingForm.useDate)
      if (this.purifiedWaterUserTendingFormTitle === '纯化水系统使用、维护保养记录详情') {
        this.purifiedWaterUserTendingDialogVisible = false
        return
      }
      this.$refs.purifiedWaterUserTendingFormRef.validate(async valid => {
        if (valid) {
          // this.purifiedWaterUserTendingForm.detailJson = JSON.stringify(this.$refs.xTable.getTableData().tableData)
          if (this.purifiedWaterUserTendingFormTitle === '新增纯化水系统使用、维护保养记录') {
            this.purifiedWaterUserTendingForm.id = ''
            await addPurifiedWaterUserTending(this.purifiedWaterUserTendingForm)
          } else if (this.purifiedWaterUserTendingFormTitle === '修改纯化水系统使用、维护保养记录') {
            await updatePurifiedWaterUserTending(this.purifiedWaterUserTendingForm)
          }
          this.purifiedWaterUserTendingPage = await selectPurifiedWaterUserTendingList(this.searchForm)
          this.purifiedWaterUserTendingDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.purifiedWaterUserTendingFormTitle = '新增纯化水系统使用、维护保养记录'
      this.purifiedWaterUserTendingDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deletePurifiedWaterUserTending(row.id)
        if (this.purifiedWaterUserTendingPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.purifiedWaterUserTendingPage = await selectPurifiedWaterUserTendingList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.purifiedWaterUserTendingFormTitle = '修改纯化水系统使用、维护保养记录'
      this.purifiedWaterUserTendingDialogVisible = true
      this.selectPurifiedWaterUserTendingInfoById(row.id)
    },
    handleInfo (index, row) {
      this.purifiedWaterUserTendingFormTitle = '纯化水系统使用、维护保养记录详情'
      this.purifiedWaterUserTendingDialogVisible = true
      this.selectPurifiedWaterUserTendingInfoById(row.id)
    },
    selectPurifiedWaterUserTendingInfoById (id) {
      selectPurifiedWaterUserTendingInfo(id).then(res => {
        this.purifiedWaterUserTendingForm.id = res.id
        this.purifiedWaterUserTendingForm.deviceId = res.deviceId
        this.purifiedWaterUserTendingForm.deviceNo = res.deviceNo
        this.purifiedWaterUserTendingForm.deviceName = res.deviceName
        this.purifiedWaterUserTendingForm.useDate = res.useDate
        this.purifiedWaterUserTendingForm.useTimePeriod = res.useTimePeriod
        this.purifiedWaterUserTendingForm.cleaningSituation = res.cleaningSituation
        this.purifiedWaterUserTendingForm.beforeUse = res.beforeUse
        this.purifiedWaterUserTendingForm.afterUse = res.afterUse
        this.purifiedWaterUserTendingForm.tending = res.tending
        this.purifiedWaterUserTendingForm.user = res.user
        this.purifiedWaterUserTendingForm.remarks = res.remarks
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectPurifiedWaterUserTendingList(this.searchForm).then(res => {
        this.purifiedWaterUserTendingPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectPurifiedWaterUserTendingList(this.searchForm).then(res => {
        this.purifiedWaterUserTendingPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectPurifiedWaterUserTendingList(this.searchForm).then(res => {
        this.purifiedWaterUserTendingPage = res
      })
    },
    devChange (value) {
      if (value) {
        let dev = this.devList.find(item => item.id === value)
        this.purifiedWaterUserTendingForm.deviceNo = dev.deviceNo
        this.purifiedWaterUserTendingForm.deviceName = dev.deviceName
      } else {
        this.purifiedWaterUserTendingForm.deviceNo = ''
        this.purifiedWaterUserTendingForm.deviceName = ''
      }
    },
    async insertRowEvent (row) {
      let { row: newRow } = await this.$refs.xTable.insertAt({}, -1)
      await this.$refs.xTable.setActiveRow(newRow)
    },
    removeRowEvent (row) {
      this.$refs.xTable.remove(row)
    }
  }
}
</script>

<style>
</style>
